/* main.css */


/* In a CSS file, for example, styles.css */
@font-face {
  font-family: 'Akrobat';
  src: local('Akrobat'), url('./fonts/Akrobat.woff2') format('woff2'),
       url('./fonts/Akrobat.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Akrobat';
  src: local('AkrobatBlack'), url('./fonts/AkrobatBlack.woff2') format('woff2'),
       url('./fonts/AkrobatBlack.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ComicSansMS';
  src: local('AkrobatBlack'), url('./fonts/ComicSansMS.woff2') format('woff2'),
       url('./fonts/ComicSansMS.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}



html, body {
    height: 100%;
    margin: 0;
  }
  
  #page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  #content-wrap {
    flex: 1;
  }
  
  /* #footer {
    /* Footer styles, such as height, background-color, etc. 
  } */
  

body {
    background-color: #f8f9fa;
  }
  
  .active {
    color: #fff;
    background-color: #730598;
  }
  

  .calculation-result pre {
    border: 1px solid green;
    padding: 10px;
  }

  .schedule-result {
    border: 1px solid green;
    padding: 10px;
  }

  .badminton-logo {
    height: 50px;
  }

  .navbar-dark {
    background-color: black !important;
  }

  .rider-button-1 {
    background-color: rgb(144, 0, 255);
    border-radius: 0;
    width: 100%;
    color: white;
    font-weight: bold;
  }

  .rider-button-1:hover {
    background-color: rgba(109, 0, 192, 0.966);
    color: white;
  }

  .rider-button-2 {
    background-color: rgb(255, 81, 0);
    border-radius: 0;
    width: 100%;
    color: white;
    font-weight: bold;
  }

  .rider-button-2:hover {
    background-color: rgba(203, 74, 0, 0.94);
    color: white;
  }


  .rider-button-3 {
    background-color: rgb(2, 130, 209);
    border-radius: 0;
    width: 100%;
    color: white;
    font-weight: bold;
  }

  .rider-button-3:hover {
    background-color: rgba(2, 130, 209, 0.94);
    color: white;
  }



  #footer {
    background-color: #730598;
    padding: 10px;
    color: white;
  }

  #footer a {
    color: white;
  text-decoration: none;
  }

  .btn {
    border-radius: 0;
  }

  .player-block {
    padding: 10px;
    border: 1px solid #730598;
    margin: 0px 0 10px 5px;
  }



  #overlay-content .overlay {
    position: absolute;
    bottom: 20px;
    left: 40px;
    top: 40px;
  }



  #overlay-content .overlay .overlay-text-1 {
    font-size: 3em;
  }

  #overlay-content .overlay .overlay-text-2 {
    font-size: 4em;
    margin-top: -30px;
  }

  #overlay-content .overlay .overlay-text-3 {
    font-size: 2em;
    margin-top: 60px;
  }

  #overlay-content .overlay .overlay-text-4 {
    font-size: 3em;
    margin-top: -25px;
  }


  #overlay-content .overlay .overlay-text-5 {
    margin-top: 100px;
  }


  .preview-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 20px 0;
  }
  
  .button-block {
    margin-top: 20px;
  }


.button-block .download-button {
  margin-left: 20px;
}

@media (max-width: 768px) {
  .overlay-text-1, .overlay-text-2, .overlay-text-3, .overlay-text-4, .overlay-text-5, .overlay-text-6, .overlay-text-7 {
    font-size: 14px; /* Smaller font size for smaller screens */
  }
}